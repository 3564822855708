
            @import 'src/styles/mixins.scss';
        
.footer {
    border-color: #e5e5e5;
    position: relative;
    .inner {
        border-color: #e5e5e5;
    }
}
.footer-menu {
    @media screen and (max-width: 414px) {
        margin-left: -20px;
        margin-right: -20px;
    }
}
