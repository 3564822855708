
            @import 'src/styles/mixins.scss';
        
.contentStyle {
    p {
        margin-top: 1.75rem;
        font-weight: 300;
        font-size: 16px;
        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
    a {
        color: blue;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
