
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.legal {
    font-family: "Poppins", sans-serif;
    padding-bottom: 2rem;
    @apply px-4 lg:px-7;

    section {
        padding: 1.5rem 0;
        border-bottom: 1px solid;
        border-color: $perfect-auto-warranty-shining-silver;
        p {
            margin-bottom: 1.5rem;
            &:last-child {
                margin-bottom: 0;
            }
            @apply text-sm lg:text-base font-light;
        }
        h2 {
            @apply font-bold mb-4 text-lg lg:text-xl;
        }
        ul {
            list-style-type: disc;
            padding-left: 15px;
            margin-bottom: 2rem;

            li {
                margin-bottom: 1rem;
                padding-left: 5px;
                @apply font-light text-sm lg:text-base;
                &::last-child {
                    margin-bottom: 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            text-decoration: underline;
            color: blue;
        }

        h3 {
            @apply font-medium text-base lg:text-lg mb-3;
        }
        h4 {
            font-weight: 700;
        }

        &:last-child {
            border: none;
        }
    }
}
